<template>
    <div class="personal-info">
      <h1>个人信息</h1>
      <p><strong>姓名：</strong>{{ name }}</p>
      <p><strong>年龄：</strong>{{ age }}</p>
      <p><strong>邮箱：</strong>{{ email }}</p>
      <p><strong>地址：</strong>{{ address }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PersonalInfo',
    data() {
      return {
        name: '张三',
        age: 30,
        email: 'zhangsan@example.com',
        address: '中国北京市'
      };
    }
  };
  </script>
  
  <style scoped>
  .personal-info {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .personal-info h1 {
    color: #333;
  }
  
  .personal-info p {
    margin: 5px 0;
  }
  </style>