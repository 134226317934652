<template>
  <div id="app">
    <PersonalInfo />
    <a id="beian" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备20010815号-1</a>
  </div>
</template>

<script>
import PersonalInfo from './components/PersonalInfo.vue';

export default {
  name: 'App',
  components: {
    PersonalInfo
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
  
}
#beian {
  }
</style>